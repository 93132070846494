.SearchInput
  display: flex

  padding: 0 16px

  .Header &
    padding: 0

  .Input
    width: 100%
    padding: 10px 16px
    height: 44px !important
    min-height: 44px !important

.SearchInput__input
  flex: 1
  position: relative

.SearchInput__cancel
  position: absolute
  right: 0
  top: 0
  width: 44px
  height: 44px
  display: flex
  align-items: center
  justify-content: center
  color: var(--foreground-tertiary)
  transition: opacity 150ms ease-in-out
  cursor: pointer

  &:active
    opacity: var(--active-opaicty)

.SearchInput__buttons
  flex: 0 0 auto

  .Button
    padding: 10px
    margin-left: 8px
    border-radius: 16px
