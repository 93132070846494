
.bg-photo-wrap {
  width: 100%;
  height: 350px;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}
.bg-photo {
  width: 100%;
  background-size: cover;
  height: 300px;
  transform: scale(1.3);
  filter: blur(30px) brightness(70%);
}
.content {
  max-width: 607px;
  padding: 40px 0px;
  margin: 0 auto;
}
.profile {
  text-align: center;
  background: #1C1D1F;
  border-radius: 15px;
  padding-bottom: 25px;
  margin: 175px 10px 0px;
}
.profile-photo {
  border-radius: 100%;
  border: 12px solid #19191A;
  margin-top: -85px;
  width: 150px;
}
.profile-line {
  color: #818C99;
  margin: 10px;
}
.profile-name {
  font-size: 20px;
  padding-top: 20px;
}
.post {
  background: #141414;
  border-radius: 15px;
  margin: 20px 10px;
  padding: 20px 20px;
}
.post-question {
  font-weight: bold;
}
.post-answer {
  margin-top: 7px;
}
.profile-actions {
  padding-top: 10px;
}
.profile-ask {
  background: #36D96C;
  padding: 12px 40px 13px;
  border-radius: 30px;
  display: inline-block;
  text-decoration: none;
  color: #141414;
}
.profile-ask:hover {
  background: #2fca63;
  cursor: pointer;
}
.profile-ask-icon {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
.profile-ask-text {
  padding: 2px 0px 0px;
  display: inline-block;
}
.post-photo {
  margin-top: 15px;
}
.post-photo-url {
  width: 300px;
  border-radius: 10px;
}
.post-date {
  color: #818C99;
  font-size: 14px;
  padding: 5px 0px 0px;
}
.post-empty-title {
  font-weight: bold;
  font-size: 20px;
}
.post-empty {
  text-align: center;
  padding: 30px 30px 40px;
  line-height: 25px;
}
.post-empty-text {
  font-size: 18px;
  color: #71767A;
  padding: 10px 10px 20px;
}
.snackbar {
  width: 300px;
  background: #1D1E1F;
  padding: 9px 10px;
  position: fixed;
  bottom: 15px;
  left: 50%;
  border-radius: 15px;
  margin: 0 auto;
  margin-left: -160px;
}
.snackbar-action {
  color: #65CC5C;
  float: right;
  text-decoration: none;
  display: inline-block;
  padding: 10px;
}
.snackbar-text {
  display: inline-block;
  padding: 10px;
}
.logo-wrap {
  position: absolute;
  margin: 10px 3px;
}
.logo-holder {
  max-width: 607px;
  margin: 0 auto;
}
.center {
  width: 350px;
  position: fixed;
  left: 50%;
  margin-left: -175px;
  top: 50%;
  margin-top: -200px;
  text-align: center;
}
.post-actions {
  margin-top: 14px;
}
.post-act {
  display: inline-block;
  text-decoration: none;
  background: #1D1E1F;
  border-radius: 25px;
  padding: 8px 10px 7px;
  color: #71767A;
  vertical-align: middle;
  cursor: pointer;
  height: 21px;
}
.post-act:hover {
  background: #2a2c2e;
}
.post-act-right {
  float: right;
  margin-left: 10px;
}
.post-act-like {
  min-width: 23px;
  display: inline-flex;
  align-items: center;
}
.post-like-count {
  padding-left: 2px;
  padding-right: 2px;
}
.post-like-icon {
  padding-top: 0px;
  margin-right: 2px;
  margin-left: 2px;
}
.post-like-count {
  display: inline-block;
}
.post-act-actions {
  padding: 7px 10px 8px;
}
.post-act-share {
  padding: 8px 10px 7px;
}
.download-android {
  float:right;
  margin: 10px 5px;
}
.download-ios {
  float:right;
  margin: 10px 5px;
}
.popup_wrap {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.popup {
  width: 300px;
  background: #131414;
  border-radius: 40px;
  text-align: center;
  padding: 40px 20px 20px;
  box-shadow: 0px 0px 344px 47px rgba(0,0,0,1);
}
.popup_desc {
  padding: 20px 0px;
  font-size: 20px;
}
.popup_btn {
  background: #65CC5C;
  color: #000;
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.popup_btn img {
  margin-right: 3px;
}
#amafan_frame_cont {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
.empty_msg {
  padding: 40px 0px;
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
  max-width: 607px;
  font-size: 20px;
  width: 100%;
}
#ether-canvas {
  display: block;
  position: fixed;
}
.main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height:100%;
  background: var(--background-primary);
}
.main-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height:100%;
}
@media screen and (max-width: 900px) {
  .main-columns {
    flex-direction: column;
    height: auto;
  }
}
.left-part {
  padding: 10px 30px 80px;
  max-width: 600px;
}
.right-part {
  padding-bottom: 70px;
}
.left-download {
  padding: 110px 10px;
}
.left-download h1 {
  font-size: 48px;
  line-height: 58px;
  font-family: 'Rubik', Arial, Helvetica, sans-serif;
}
.landing_btns {
  display: flex;
  margin-top: 10px;
}
.landing_btn {
  background: #65CC5C;
  color: #000;
  padding: 15px 21px 15px 20px;
  border-radius: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: 24px;
  margin-left: 10px;
}
.landing_btn:first-child {
  margin-left: 0;
}
.landing_btn:hover {
  background: #FFF;
}
.landing_btn img {
  margin-right: 3px;
}
.logo-landing {
  margin-bottom: 200px;
}
.right-screen-img {
  width: 500px;
  margin: 20px;
}
@media screen and (max-width: 700px) {
  .right-screen-img {
    width: 500px;
  }
}
@media screen and (max-width: 600px) {
  .right-screen-img {
    width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .right-screen-img {
    width: 300px;
  }
}
@media screen and (max-width: 350px) {
  .right-screen-img {
    width: 200px;
  }
}
.footer {
  background: rgba(26, 26, 26, 0.8);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.footer-left {
  flex: auto;
  padding: 15px 20px;
  max-width: 990px;
}
.footer-left a {
  color: #FFF;
  text-decoration: none;
  padding: 10px 10px;
  display: inline-block;
}
.footer-left a:hover {
  background: #333;
  border-radius: 10px;
}
.footer-right {
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  line-height: 24px;
  color: #71767A;
}
.download_open {
  display: block;
  padding: 17px 10px;
  border-radius: 75px;
  background: #007aff;
  color: #FFF;
  text-decoration: none;
  margin-top: 12px;
}
.download_or {
  margin-bottom: -6px;
  margin-top: 10px;
  color: #FFF;
}

.main-logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.main-logo svg {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  .left-download {
    padding: 32px 0 0;
  }
  .left-part {
    padding-bottom: 20px;
  }
  .landing_btns {
    flex-direction: column;
  }
  .landing_btn {
    margin-left: 0;
    margin-top: 12px;
  }
}
