.BottomSheet
  position: fixed
  z-index: 1001
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: flex-end
  box-sizing: border-box
  justify-content: center

  .CellButton__in
    background: var(--background-secondary)

.BottomSheet__close_helper
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1
  background: rgba(0, 0, 0, 0.72)

.BottomSheet__sheet_wrap
  transform: translateY(100%)
  width: 100%
  position: relative
  z-index: 2
  max-width: 500px

.BottomSheet__sheet
  background: var(--background-primary)
  border-radius: 16px 16px 0 0
  overflow: hidden

.BottomSheet__header
  height: 64px
  padding-left: 16px
  display: flex
  align-items: center

.BottomSheet__header__title
  font-weight: bold
  font-size: 20px
  line-height: 28px
  flex: 1
  overflow: hidden
  text-overflow: ellipsis

.BottomSheet__header__close_wrap
  width: 64px
  height: 64px
  display: flex
  align-items: center
  justify-content: center
  flex: 0 0 auto
  cursor: pointer

.BottomSheet__header__close
  width: 32px
  height: 32px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  background: var(--background-secondary)

.BottomSheet__content
  padding-bottom: 16px
