.Tests__intro
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 16px
  box-sizing: border-box
  text-align: center
  color: #000

.Tests__intro__cont
  background: #F0F2F5
  border-radius: 16px
  padding: 32px 16px
  max-width: 400px
  box-sizing: border-box
  width: 100%

  &.test
    text-align: left

.Tests__intro__name
  font-size: 32px
  line-height: 36px
  font-weight: bold

.Tests__intro__description
  font-size: 16px
  line-height: 22px
  margin-top: 12px
  color: #333

.Tests__intro__action
  margin-top: 24px

.Tests__question
  font-size: 18px
  font-weight: bold
  line-height: 22px

.Tests__answers
  margin-top: 16px

.Tests__answer
  padding: 8px 12px
  font-size: 16px
  line-height: 28px
  background: rgba(206, 208, 214, 0.24)
  border-radius: 8px
  margin-top: 8px
  cursor: pointer

  &:first-child
    margin-top: 0

.Tests_copyright
  display: flex
  align-items: center
  margin: 12px 12px 32px
  text-decoration: none

.Tests_copyright__photo
  flex: 0 0 auto
  width: 52px
  height: 52px
  border-radius: 50%
  background: no-repeat center/cover url('https://anime.fans/assets/icon.svg')

.Tests_copyright__info
  font-size: 15px
  line-height: 18px
  text-align: left
  margin-left: 12px
  flex: 1
  color: var(--foreground-primary)

.Tests_copyright__chevron
  flex: 0 0 auto
  margin-left: 16px
  color: var(--foreground-secondary)