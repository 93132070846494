.Auth__phone_sent_label
  display: flex
  align-items: center
  flex-wrap: wrap

  span
    margin-right: 6px

.Auth__phone_sent__change
  color: var(--tint)
