.Main
  padding-top: env(safe-area-inset-top)
  padding-left: env(safe-area-inset-bottom)
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center
  background: var(--background-primary)
  border-radius: 16px

  .Input
    background: var(--background-secondary)

    .PhoneInput__country_code
      background: var(--background-tertiary)

  .Button.secondary
    background: var(--background-secondary)

.Main__info
  padding: 32px
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.Main__logo
  font-weight: 800
  font-size: 20px
  line-height: 28px
  flex: 0 0 auto
  text-align: center
  margin-top: 16px

.Main__description
  font-weight: 800
  font-size: 36px
  line-height: 44px
  text-align: center
  margin-top: 24px

.Main__links
  display: flex
  padding-top: 12px
  flex-wrap: wrap

.Main__link
  display: block
  padding: 10px 16px
  border-radius: 16px
  color: var(--foreground-primary)
  text-decoration: none
  font-size: 16px
  line-height: 20px
  background: var(--background-tertiary)
  margin: 4px
  transition: background-color 150ms ease-in-out

  &:hover, &:active
    background-color: var(--background-tertiary)

.Main__login_form
  width: 100%
  max-width: 400px
  padding: 0 16px
  box-sizing: border-box
  flex: 0 0 auto

.Main__captcha_wrap
  display: flex
  align-items: center
  justify-content: center
  position: relative
  height: 72px
  width: 100%
  flex: 0 0 auto !important

.Main__captcha_loader
  z-index: 1
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.Main__captcha__cont
  width: 100%
  height: auto
  flex: 0 0 auto
  z-index: 2
  position: relative

.Main__privacy
  font-size: 15px
  line-height: 18px
  padding-bottom: 12px

  &, a
    color: var(--foreground-secondary)

  a
    text-decoration: underline

.Main__auth_methods
  padding-bottom: 16px
  .Button
    margin-top: 12px

    &:first-child
      margin-top: 0

.Main__buttons_row
  display: flex
  width: 100%

  .Button
    margin-left: 8px
    flex: 1

    &:first-child
      margin-left: 0

.Main__explore
  margin-top: 16px
  padding-bottom: 32px

  .InlineHeader
    margin-top: 16px

  .Post:first-child
    margin-top: 8px

.Main__explore__loader
  display: flex
  height: 200px
  width: 100%
  align-items: center
  justify-content: center

@media screen and (min-height: 900px)
  .Main
    justify-content: center
    margin-top: 32px

  .Main__info
    flex: 0 0 auto
    padding-top: 0
