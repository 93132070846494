.LinkAttach
  border: 1px solid rgba(255, 255, 255, 0.2)
  padding: 12px
  border-radius: 12px
  display: block

  .Message__attachments &
    border: 0
    background: var(--background-secondary)
    border-radius: 0

  .AttachPickerManager__link &
    pointer-events: none

  .Post &
    margin-left: 16px
    margin-right: 16px

  .Collage_wrap + &
    margin-top: 12px

.LinkAttach__type
  font-size: 15px
  line-height: 18px
  color: var(--foreground-tertiary)

.LinkAttach__body
  display: flex
  margin-top: 8px

.LinkAttach__image
  width: 96px
  height: 96px
  flex: 0 0 auto
  background: no-repeat center/cover
  border-radius: 12px

  .LinkAttach.anime &
    width: 100px
    height: 136px

  .LinkAttach.post &
    width: 54px
    height: 54px

.LinkAttach__cont
  margin-left: 12px
  overflow: hidden
  max-height: 136px

.LinkAttach__title
  font-size: 18px
  line-height: 22px
  font-weight: 800
  flex: 0 0 auto
  overflow: hidden
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  max-lines: 2
  display: -webkit-box
  color: var(--foreground-primary)

.LinkAttach__description
  font-size: 16px
  line-height: 20px
  margin-top: 4px
  color: var(--foreground-primary)
  overflow: hidden
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  max-lines: 2
  display: -webkit-box

.LinkAttach__button
  margin-top: 8px

  .Button
    flex: 0 0 auto
    width: auto
    display: inline-flex

.AttachPickerManager__link.mini
  .LinkAttach__title
    -webkit-line-clamp: 1
    font-size: 16px
    line-height: 20px

  .LinkAttach__description
    -webkit-line-clamp: 1
    font-size: 14px
    line-height: 18px
    margin-top: 2px

  .LinkAttach__button, .LinkAttach__type
    display: none

  .LinkAttach__image
    width: 42px
    height: 42px

  .LinkAttach__body
    margin-top: 0
