.EpisodeItem
  display: flex
  padding: 8px 16px
  align-items: center
  border-radius: 16px

  &.selected
    background: var(--background-secondary)

.EpisodeItem__poster
  flex: 0 0 auto
  width: 80px
  height: 45px
  background: var(--background-tertiary) no-repeat center/cover
  position: relative
  border-radius: 8px

.EpisodeItem__title
  margin-left: 16px
  font-size: 18px
  font-weight: 800
  line-height: 22px
  color: var(--foreground-primary)
  flex: 1

  sup
    color: var(--foreground-tertiary)

.EpisodeItem__progress
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  height: 4px

.EpisodeItem__progress__indicator
  background: var(--tint-negative)
  height: 4px

.EpisodeItem__arrow
  width: 16px
  height: 16px
  color: var(--foreground-tertiary)
