.Button
  background: var(--tint)
  color: var(--constant-dark)
  padding: 13px 24px
  display: flex
  text-align: center
  border-radius: 24px
  justify-content: center
  align-items: center
  transition: all 150ms ease-in-out
  cursor: pointer
  text-decoration: none
  text-overflow: ellipsis
  overflow: hidden
  box-sizing: border-box

  &.stretched
    width: 100%

  &:active
    opacity: 0.7

  &.disabled
    background: var(--background-secondary)
    color: var(--foreground-secondary)
    pointer-events: none

  &.dark
    background: var(--dark-button)
    color: var(--dark-button-color)

  &.red
    background: var(--tint-negative)
    color: var(--constant-dark)

  &.inline
    background: transparent
    color: var(--tint)

  &.light
    background: var(--constant-light)
    color: var(--constant-dark)

  &.small
    padding: 7px 12px
    border-radius: 20px

  &.secondary
    background: var(--background-primary)
    color: var(--foreground-tertiary)

  &.inline
    background: transparent
    color: var(--foreground-tertiary)

.Button__icon
  &, svg
    width: 24px
    height: 24px

.Button__text
  padding: 0 8px
  font-weight: 800
  font-size: 16px
  line-height: 22px

  .Button.small &
    padding: 0 4px
