.AuthRequired
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  min-height: 100vh
  flex-direction: column
  padding: 100px 16px
  box-sizing: border-box
  text-align: center

.AuthRequired__title
  font-size: 26px
  line-height: 32px
  font-weight: bold

.AuthRequired__caption
  margin-top: 12px
  font-size: 18px
  line-height: 24px
  color: var(--foreground-tertiary)

.AuthRequired__form
  margin-top: 32px
