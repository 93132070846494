.PhotoView
  display: flex
  flex-direction: column
  width: 100%
  height: 100%

  .desktop &
    height: 700px

  .mobile &
    position: fixed
    top: 0
    left: 0
    height: 100vh
    width: 100vw

    .Header
      background: rgba(0,0,0,0.6)
      transition: opacity 150ms ease-in-out
      position: absolute
      top: 0
      left: 0
      width: 100%
      z-index: 3

  &.hideControls .Header
    opacity: 0
    pointer-events: none

.PhotoView__bg
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1
  background: #000

.PhotoView__content
  flex: 1
  display: flex
  flex-direction: column
  z-index: 2
  position: relative

.PhotoView__container
  flex: 1
  display: flex
  overflow: hidden
  touch-action: none

.PhotoView__photos
  flex: 1
  display: flex
  flex-wrap: nowrap

.PhotoView__photo__container
  width: 100%
  flex: 0 0 auto
  height: 100%
  display: flex
  margin-left: 16px

  &:first-child
    margin-left: 0

.PhotoView__photo
  flex: 1
  background: no-repeat center/contain

.PhotoView__open
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 10
  opacity: 0
