.Other__profile
  display: flex
  align-items: center
  padding: 16px
  text-decoration: none
  border-radius: 16px

  &:active
    background: var(--background-tertiary)

.Other__profile__photo
  width: 52px
  height: 52px
  background: no-repeat center/cover
  border-radius: 12px
  flex: 0 0 auto

.Other__profile__cont
  margin-left: 16px
  flex: 1

.Other__profile__name
  font-weight: 800
  font-size: 18px
  line-height: 22px
  color: var(--foreground-primary)

.Other__profile__caption
  color: var(--foreground-tertiary)
  font-size: 16px
  line-height: 22px
  margin-top: 8px
  display: flex
  align-items: center

  svg
    margin-left: 8px

.Other__actions
  margin-top: 16px
