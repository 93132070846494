.VideoPlayer__wrap
  position: relative
  max-width: var(--max-width)

.VideoPlayer__fixed
  //position: fixed
  //z-index: 1000
  //width: 100%

  .desktop &
    width: var(--max-width)

.VideoPlayer
  position: relative
  padding-top: 56%
  border-radius: 16px
  overflow: hidden
  background: var(--background-primary)

.VideoPlayer__video
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  outline: none
