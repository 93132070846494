body, input, textarea
  font-family: 'Nunito', sans-serif

html, body
  height: 100%

body
  color: var(--foreground-primary)
  padding: 0
  margin: 0
  user-select: none
  background: var(--background-zero)
  overflow: hidden
  display: flex
  flex-direction: column

  &.white
    background: #fff

*
  -webkit-tap-highlight-color: transparent

a
  text-decoration: none

\:root
  --navbar-width: 260px
  --active-opaicty: 0.7
  --max-width: 800px
  --photo-border-radius: 12px

  --foreground-primary: #0B0D14
  --foreground-secondary: #71737A
  --foreground-tertiary: #B0B2B8
  --background-zero: #fff
  --background-primary: #F0F2F5
  --background-secondary: rgba(206, 208, 214, 0.24)
  --background-tertiary: rgba(169, 173, 184, 0.24)
  --background-page: #fff
  --background-quaternary: rgba(169, 173, 184, 0.24)
  --border: rgba(255, 255, 255, 0.16)
  --shadow: rgba(25, 25, 26, 0)
  --overlay-medium: rgba(0, 0, 0, 0.64)

  --tint: #62D962
  //--tint: #498FF2
  --tint-purple: #7341D8
  --tint-gold: #FFA526
  --dark-button: #000
  --dark-button-color: #fff

  --constant-dark: #000000
  --constant-light: #FFFFFF

  --tint-like: #45E688
  --tint-dislike: #FF4070
  --tint-negative: rgba(242, 36, 88, 1)
  --overlay-heavy: rgba(0, 0, 0, 0.84)
  --overlay-light: rgba(0, 0, 0, 0.16)
  --tint-blue: #3380CC

  --gradient-purple: linear-gradient(137.61deg, #4830BF 0%, #A455F2 100%)
  --gradient-pink:  linear-gradient(137.61deg, #E64588 0%, #FF66BF 100%)
  --gradient-blue:  linear-gradient(137.61deg, #3380CC 0%, #55D8F2 100%)
  --gradient-gold:  linear-gradient(137.61deg, #F27318 0%, #FFCC33 100%)

body[scheme="dark"]
  --foreground-primary: #fff
  --foreground-secondary: #EBEBEB
  --foreground-tertiary: #7F8185
  --background-zero: #000
  --background-primary: #1D1D1F
  --background-secondary: #272729
  --background-tertiary: #313233
  --background-page: #000
  --background-quaternary: rgba(117, 118, 122, 0.32)
  --border: rgba(255, 255, 255, 0.16)
  --shadow: rgba(25, 25, 26, 0)
  --overlay-medium: rgba(0, 0, 0, 0.64)
  --tint-purple: #8250E5
  --tint-gold: #FFCF0D
  --dark-button: #fff
  --dark-button-color: #000

  --accent-color-purple: #8250E6
  --accent-gradient-purple: linear-gradient(to right bottom, #AA61F2 0%, #6950E6 100%)

  --accent-color-orange: #FF9F40
  --accent-gradient-orange: linear-gradient(to right bottom, #FFC44D 0%, #FF8040 100%)

  --accent-color-pink: #E650B4
  --accent-gradient-pink: linear-gradient(to right bottom, #F261CE 0%, #E6508E 100%)

  --accent-color-cyan: #4CB6D9
  --accent-gradient-cyan: linear-gradient(to right bottom, #5CDAE6 0%, #4C9ED9 100%)

  --accent-color-red: #E64545
  --accent-gradient-red: linear-gradient(to right bottom, #F26F55 0%, #E64560 100%)

  --accent-color-green: #79BF56
  --accent-gradient-green: linear-gradient(to right bottom, #99CC66 0%, #56BF56 100%)

  --accent-color-blue: #5076E6
  --accent-gradient-blue: linear-gradient(to right bottom, #619DF2 0%, #505DE6 100%)

  --accent-color-grey: #8994A3
  --accent-gradient-grey: linear-gradient(to right bottom, #9AA6B8 0%, #78818F 100%)

@media (min-width: 800px)
  \:root
    --max-width: 600px


.App
  .Select__indicators
    margin-right: 12px
    color: var(--foreground-tertiary)

  .Select__control
    background-color: var(--background-primary)
    border: 0
    min-height: 48px
    box-shadow: none
    border-radius: 16px
    position: relative

  .Select__input, .Select__single-value
    color: var(--foreground-primary)

  .Select__placeholder
    font-size: 18px

  .Select__indicator-separator
    display: none

  .Select__value-container
    padding: 8px 12px

  .Select__option, .Select__menu
    background-color: var(--background-tertiary)

  .Select__menu
    border-radius: 16px
    overflow: hidden
    z-index: 1000

  .Select__menu-list
    max-height: 250px

  .Select__option
    padding: 12px 16px
    outline: none
    box-shadow: none
    color: var(--foreground-primary)

    &:active
      background-color: var(--background-quaternary)

  .Select__multi-value
    background-color: var(--background-quaternary)
    border-radius: 8px
    color: var(--foreground-primary)

  .Select__multi-value__label
    color: var(--foreground-primary) !important
    padding: 5px
    font-size: 14px
    line-height: 16px

  .Select__multi-value__remove svg
    width: 18px
    height: 18px

.App, #root
  display: flex
  flex-direction: column
  height: 100%

.desktop
  #root, .App
    height: 100vh

.Modal__wrap, .Content__wrap
  width: 100%
  max-width: var(--max-width)
  margin: 0 auto
  flex: 1
  display: flex
  flex-direction: column
  box-sizing: border-box
  overflow: hidden

.fullWidth .Content__wrap
  max-width: 100%
  overflow-y: auto

::selection
  color: var(--foreground-primary)
  background: rgba(255,255,255,0.3)

.App__desktop_wrap
  display: flex

.App__desktop_menu
  width: 260px
  height: 100px

.App__desktop_cont
  flex: 1
  position: relative
  padding: 0 16px

.Modal__cont
  min-height: 100%
  display: flex
  flex-direction: column
  overflow: hidden
  background: var(--background-zero)

  .Modal__wrap.isPhotos &
    background: transparent

.mobile
  .Modal__wrap
    position: absolute
    z-index: 100
    top: 0
    left: 0
    right: 0
    bottom: 0

.desktop
  .Modal__wrap
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    max-width: 100%
    align-items: center
    justify-content: center
    background: rgba(0,0,0,0.7)
    z-index: 1001
    margin: 0
    padding: 32px

  .Modal__cont
    max-width: var(--max-width)
    background: var(--background-primary)
    border-radius: 16px
    width: 100%
    position: relative
    min-height: 0
    overflow-y: auto

    .Button.secondary, .Input
      background: var(--background-secondary)

    .Post, .Comment
      background: var(--background-zero)

    .Comment .Comment
      background: var(--background-tertiary)

    .Header
      border-radius: 16px 16px 0 0
      background: var(--background-primary)

    .CommentForm
      position: absolute

    .BottomBar.fixed .BottomBar__in
      background: var(--background-primary)
