.SegmentedControl
  padding: 4px
  box-sizing: border-box
  width: 100%
  background: rgba(206, 208, 214, 0.24)
  background: var(--background-primary)
  border-radius: 16px
  height: 48px

.SegmentedControl__in
  position: relative
  display: flex
  height: 40px
  align-items: center

.SegmentedControl__item
  flex: 1
  z-index: 2
  transition: opacity 150ms ease-in-out
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  position: relative
  font-size: 16px
  line-height: 20px
  min-width: 48px
  cursor: pointer

  span
    color: var(--foreground-tertiary)
    margin-left: 4px
    line-height: 22px

  &.selected
    pointer-events: none

  &:active
    opacity: var(--active-opaicty)

  &:before
    content: ''
    display: block
    left: 0
    top: 8px
    bottom: 8px
    width: 1px
    background: var(--border)
    position: absolute
    transition: opacity 150ms ease-in-out

  &:first-child
    &:before
      display: none

  &.selected:before
   opacity: 0

  &.selected + .SegmentedControl__item:before
    opacity: 0


.SegmentedControl__indicator
  background: #fff
  background: var(--background-zero)
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04)
  border-radius: 12px
  position: absolute
  z-index: 1
  height: 100%
  top: 0
  left: 0
  transition: transform 150ms ease-in-out
  pointer-events: none

  .ModalPage__in &
    background: var(--background-secondary)

