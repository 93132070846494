.NotifyItem
  padding: 8px 16px
  color: var(--foreground-primary)
  text-decoration: none
  display: flex

.NotifyItem__avatars
  flex: 0 0 auto
  position: relative
  margin-right: 16px
  width: 52px
  height: 52px

.NotifyItem__avatars__item
  position: absolute
  border-radius: 50%
  background: no-repeat center/cover

  .NotifyItem__avatars.single &
    top: 0
    left: 0
    width: 100%
    height: 100%

  .NotifyItem__avatars.multi &
    width: 65%
    height: 65%

    &:first-child
      top: 0
      left: 0
      border: 2px solid var(--background-primary)
      z-index: 2

    &:last-child
      bottom: 0
      right: 0
      z-index: 1

.NotifyItem__cont
  flex: 1

.NotifyItem__label
  font-size: 16px
  line-height: 20px

.NotifyItem__date
  color: var(--foreground-tertiary)
  font-size: 14px
  line-height: 18px
  margin-top: 4px

.NotifyItem__comment
  font-size: 16px
  line-height: 20px
  margin-top: 6px
  padding: 4px 8px
  border-radius: 6px
  background: var(--background-tertiary)
  display: inline-flex

.NotifyItem__follow
  flex: 0 0 auto
  margin-left: 12px
  align-self: center
