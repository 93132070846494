.Navbar
  padding: 16px
  position: fixed
  top: 0
  left: 0
  width: var(--navbar-width)
  box-sizing: border-box

.Navbar__logo
  display: flex
  justify-content: center
  margin-bottom: 16px

.NavbarItem
  display: flex
  padding: 12px 16px
  border-radius: 16px
  text-decoration: none
  color: var(--foreground-primary)
  align-items: center

  &.active, &:hover
    background: var(--background-tertiary)

.NavbarItem__icon
  flex: 0 0 auto
  color: var(--foreground-tertiary)

  &, svg
    width: 24px
    height: 24px

.NavbarItem__label
  font-weight: 800
  font-size: 16px
  line-height: 20px
  margin-left: 16px
  flex: 1

.NavbarItem__counter
  flex: 0 0 auto
  margin-left: 12px

.NavbarItem__badge
  flex: 0 0 auto
  width: 8px
  height: 8px
  border-radius: 50%
  background: var(--tint-negative)
