.Anime__search
  display: flex

  .Input
    width: 100%
    padding: 10px 16px
    height: 44px !important
    min-height: 44px !important

  .Button
    padding: 10px
    margin-left: 8px
    border-radius: 16px

.Anime__search__input
  flex: 1
  position: relative

.Anime__search__cancel
  position: absolute
  right: 0
  top: 0
  width: 44px
  height: 44px
  display: flex
  align-items: center
  justify-content: center
  color: var(--foreground-tertiary)
  transition: opacity 150ms ease-in-out
  cursor: pointer

  &:active
    opacity: var(--active-opaicty)

.Anime__cards
  padding: 8px 0 16px

.Anime_not_found
  color: var(--foreground-tertiary)
  text-align: center
  padding: 100px 32px
  font-size: 18px
  line-height: 22px

.Anime__filters
  .Form
    padding-top: 0

.Anime__filters__group
  display: flex

  .Form__item
    flex: 1

.Anime__buttons
  padding: 16px 0
