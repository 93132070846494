.PlayerContent
  background: var(--background-primary)
  display: flex
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  user-select: none
  overflow: hidden

  &.isFullscreen
    width: 100vw
    height: 100vh

@media screen and (orientation: portrait)
  .PlayerContent.isFullscreen
    transform: rotate(-90deg) !important
    transform-origin: left top !important
    width: 100vh !important
    height: 100vw !important
    overflow-x: hidden !important
    position: absolute !important
    top: 100% !important
    left: 0 !important

.PlayerContent__video
  z-index: 1
  position: relative
  object-fit: contain
  flex: 0 0 auto

.PlayerContent__cont
  display: flex
  flex-direction: column
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  background: no-repeat center/contain
  z-index: 2
  opacity: 0
  transition: opacity 150ms ease-in-out

  .isControlsShown &
    opacity: 1

.PlayerContent__press_overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1

.PlayerContent__play_control
  position: absolute
  width: 100px
  height: 100px
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  align-items: center
  justify-content: center
  z-index: 4
  cursor: pointer
  pointer-events: none

  .isControlsShown &
    pointer-events: auto

  .desktop &
    width: 200px
    height: 200px


.PlayerContent__play_control__in
  width: 62px
  height: 62px
  border-radius: 50%
  background: rgba(0,0,0,0.6)
  display: flex
  align-items: center
  justify-content: center
  transition: transform 150ms ease-in-out

  svg
    width: 32px
    height: 32px

  .desktop &, .isFullscreen &
    width: 102px
    height: 102px

    svg
      width: 62px
      height: 62px

.PlayerContent__skip_control
  position: absolute
  top: 50%
  transform: translateY(-50%)
  width: 100px
  height: 100px
  align-items: center
  justify-content: center
  color: #fff
  z-index: 4
  cursor: pointer
  display: none

  .isControlsShown &
    display: flex

  svg
    width: 42px
    height: 42px

  svg
    filter: drop-shadow(2px 2px 12px rgb(0 0 0 / 0.6))

  &.next
    right: 16px

  &.prev
    left: 16px

  .desktop &, .isFullscreen &
    width: 200px
    height: 200px

    svg
      width: 52px
      height: 52px

.PlayerContent__footer
  position: absolute
  bottom: 0
  left: 0
  right: 0
  background: linear-gradient(180deg, rgba(21, 28, 41, 0) 0%, #000 100%)
  z-index: 3
  display: flex
  padding: 16px 8px 0
  padding-bottom: env(safe-area-inset-bottom)
  pointer-events: none

.PlayerContent__progress
  padding: 0 8px 0 16px
  flex: 1
  cursor: pointer

  .isControlsShown &
    pointer-events: auto

.PlayerContent__progress__in_wrap
  padding: 24px 0
  pointer-events: none

.PlayerContent__progress__in, .PlayerContent__progress__buffered, .PlayerContent__progress__current
  position: relative
  height: 6px
  border-radius: 3px
  background: rgba(255,255,255,0.2)

.PlayerContent__progress__buffered, .PlayerContent__progress__current
  position: absolute
  top: 0
  left: 0
  background: rgba(255,255,255,0.3)
  z-index: 1

.PlayerContent__progress__current
  z-index: 2
  background: #fff

.PlayerContent__progress__current_indicator
  position: absolute
  width: 16px
  height: 16px
  border-radius: 50%
  background: #fff
  border: 0.5px solid rgba(0, 0, 0, 0.04)
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04)
  top: -5px
  right: -5px
  box-sizing: border-box

.PlayerContent__fullscreen
  width: 54px
  height: 54px
  flex: 0 0 auto
  color: #fff
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer

  .isControlsShown &
    pointer-events: auto

  svg
    width: 28px
    height: 28px

.PlayerContent__next_button
  position: absolute
  z-index: 5
  bottom: 50px
  right: 16px
  background: #fff
  border-radius: 16px
  padding: 6px 10px
  color: #000
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2)
  cursor: pointer
  font-size: 13px
  line-height: 17px

  .isFullscreen &, .desktop &
    font-size: 16px
    line-height: 18px
    padding: 12px 24px
    font-weight: bold
    bottom: 62px
