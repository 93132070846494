.Birthday
  display: flex

  .Select
    flex: 1
    margin-left: 8px

    &:first-child
      width: 92px
      flex: 0 0 auto
      margin-left: 0

    &:last-child
      width: 112px
      flex: 0 0 auto
