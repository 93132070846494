.FindFriendItem
  display: flex
  padding: 8px 16px

.FindFriendItem__photo
  width: 82px
  height: 82px
  background: var(--background-tertiary) no-repeat center/cover
  flex: 0 0 auto
  border-radius: var(--photo-border-radius)

.FindFriendItem__cont
  flex: 1px
  margin-left: 16px

.FindFriendItem__name_wrap
  display: flex
  align-items: center

.FindFriendItem__name
  font-size: 18px
  line-height: 22px
  font-weight: 800
  color: var(--foreground-primary)
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.FindFriendItem__followers
  margin-top: 6px
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)
  display: flex
  align-items: center

.FindFriendItem__follow
  margin-top: 16px
  display: flex

.FindFriendItem__mutual_friends
  display: flex
  flex: 0 0 auto
  margin-right: 8px

.FindFriendItem__mutual_friend
  width: 32px
  height: 32px
  border-radius: 50%
  background: var(--background-tertiary) no-repeat center/cover
  flex: 0 0 auto
  margin-left: -8px
  border: 3px solid var(--background-primary)
  box-sizing: border-box

  &:first-child
    margin-left: 0
