.Collage_wrap
  position: relative

  .ExternalVideo__play_btn
    width: 62px
    height: 62px

    svg
      width: 32px
      height: 32px

.Collage__helper
  max-height: 300px

.Collage
  display: block
  position: absolute
  top: -4px
  left: -4px
  bottom: 0
  right: 0
  overflow: hidden

  &:after
    content: ''
    display: flex
    clear: both
    width: 100%

.Collage__item
  float: left
  box-sizing: border-box
  padding-top: 4px
  padding-left: 4px
  display: flex

.Collage__item__image
  background: no-repeat center/cover
  flex: 1
  position: relative
