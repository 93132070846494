.Badge
  height: 24px
  border-radius: 12px
  padding: 0 6px
  min-width: 24px
  text-align: center
  line-height: 24px
  font-weight: 800
  font-size: 15px
  color: var(--constant-dark)
  background: var(--tint)
  box-sizing: border-box
  margin-left: 12px

  &.orange
    background: var(--tint-gold)
