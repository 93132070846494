.Tabs
  display: flex
  position: relative

  &:after
    content: ''
    display: block
    position: absolute
    left: 0
    right: 0
    bottom: 0
    height: 1px
    background: var(--border)
    transform: scaleY(0.5)

  &.skipMargin
    margin: 0

.Tabs__item
  height: 42px
  padding: 0 8px
  font-weight: bold
  color: var(--foreground-tertiary)
  font-size: 16px
  line-height: 20px
  display: flex
  align-items: center
  border-bottom: 2px solid transparent
  margin-left: 12px
  cursor: pointer

  span
    color: var(--foreground-tertiary)
    margin-left: 4px
    font-weight: normal

  &:first-child
    margin-left: 0

  &.selected
    border-bottom-color: var(--tint)
    color: var(--foreground-primary)
    cursor: default
