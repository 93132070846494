.Tabbar
  flex: 0 0 auto
  width: 100%
  box-sizing: border-box
  background: var(--background-primary)
  z-index: 10
  position: relative
  -webkit-backface-visibility: hidden
  -webkit-transform: translate3d(0,0,0)

  &:before
    content: ''
    display: block
    top: 0
    left: 0
    width: 100%
    height: 1px
    background: rgba(255, 255, 255, 0.08)
    position: absolute
    transform: scaleY(0.5)

.Tabbar__cont
  display: flex
  max-width: var(--max-width)
  margin: 0 auto
  overflow: hidden
  padding-bottom: env(safe-area-inset-bottom)

.Tabbar__helper
  width: 100%
  height: 60px
  flex: 0 0 auto
  padding-bottom: env(safe-area-inset-bottom)

.Tabbar__item
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 60px
  background: var(--background-primary)
  overflow: hidden

.Tabbar__icon
  color: var(--foreground-tertiary)
  position: relative

  svg, &
    width: 24px
    height: 24px

  .Tabbar__item.active &
    color: var(--tint)

.Tabbar__label
  font-weight: 500
  font-size: 12px
  line-height: 16px
  margin-top: 4px
  color: var(--foreground-tertiary)
  text-align: center
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  width: 100%
  box-sizing: border-box
  padding: 0 2px

  .Tabbar__item.active &
    color: var(--tint)

.Tabbar__counter
  position: absolute
  top: -6px
  right: -6px

  .Badge
    background: var(--tint-negative)
    height: 20px
    min-width: 20px
    padding: 0 4px
    line-height: 20px
    font-size: 12px

.Tabbar__badge
  width: 8px
  height: 8px
  border-radius: 50%
  background: var(--tint-negative)
  border: 2px solid var(--background-primary)
  position: absolute
  top: 0
  right: 0
