.Dialog
  display: flex
  align-items: center
  padding: 8px 16px
  transition: background-color 150ms ease-in-out
  text-decoration: none
  color: var(--foreground-primary)
  overflow: hidden

  .desktop &
    border-radius: 16px

  &:active
    background: var(--background-tertiary)

.Dialog__photo
  width: 56px
  height: 56px
  border-radius: var(--photo-border-radius)
  flex: 0 0 auto
  background: no-repeat center/cover
  position: relative

.Dialog__cont
  margin-left: 16px
  flex: 1
  overflow: hidden

.Dialog__name_wrap
  display: flex
  align-items: center

.Dialog__name
  font-weight: 800
  font-size: 17px
  line-height: 22px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.Dialog__message
  margin-top: 4px
  font-size: 15px
  line-height: 18px
  color: var(--foreground-tertiary)
  display: flex
  white-space: nowrap
  overflow: hidden

.Dialog__text
  overflow: hidden
  text-overflow: ellipsis

.Dialog__date
  flex: 0 0 auto

.Dialog__unread
  margin-left: 12px
  flex: 0 0 auto
