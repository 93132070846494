.Input
  background: rgba(206, 208, 214, 0.24)
  background: var(--background-primary)
  padding: 12px 16px
  font-size: 18px
  line-height: 24px
  border-radius: 16px
  border: 0
  outline: none
  color: #0B0D14
  color: var(--foreground-primary)
  resize: none
  box-sizing: border-box
  overflow: hidden

textarea.Input
  overflow-y: auto
