.Feed__items
  padding-bottom: 16px

.Feed__logo
  font-size: 22px
  font-weight: bold
  letter-spacing: 1px
  text-transform: uppercase

.Feed_logo_img
  width: 152px
  height: 28px
  background: url('../../assets/logo.png') no-repeat center/contain

.Feed__separator
  width: 100%
  height: 1px
  background: var(--border)

.Welcome
  border-radius: 16px
  padding: 16px
  background: var(--background-secondary)
  margin-top: 16px

  ul
    padding-left: 16px
    margin-top: 0

  li
    margin-top: 8px

  a
    color: var(--foreground-primary)
    text-decoration: underline

.Welcome__title
  font-size: 22px
  line-height: 30px
  font-weight: bold

.Welcome__message
  font-size: 16px
  line-height: 22px
  margin-top: 12px

.Feed__tabs
  padding-bottom: 16px

.Feed__my_profile_btn
  width: 34px
  height: 34px
  border-radius: 50%
  background: no-repeat center/cover
  margin-left: 12px

