.CellButton
  margin-top: 16px
  padding: 0 16px
  color: var(--foreground-primary)
  display: block

  .Form__item &
    padding: 0

  &:first-child
    margin-top: 0

.CellButton__in
  border-radius: 16px
  height: 56px
  display: flex
  align-items: center
  padding: 0 16px
  box-sizing: border-box
  background: var(--background-primary)
  transition: all 150ms ease-in-out

  &:active
    background: var(--background-tertiary)

.CellButton__text
  font-weight: bold
  font-size: 18px
  line-height: 24px
  flex: 1
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

  span
    color: var(--foreground-tertiary)

.CellButton__icon
  flex: 0 0 auto
  width: 28px
  height: 28px
  margin-left: 16px
  color: var(--foreground-tertiary)
  display: flex
  align-items: center
  justify-content: center

.CellButton__indicator
  font-size: 16px
  line-height: 28px
  white-space: nowrap
  flex: 0 0 auto
  color: var(--foreground-tertiary)
