.Checkbox
  display: flex
  height: 42px
  align-items: center
  padding: 0 16px
  cursor: pointer

  &.disabled
    pointer-events: none

.Checkbox__indicator
  width: 20px
  height: 20px
  border-radius: 6px
  border: 2px solid var(--tint)
  box-sizing: border-box
  display: flex
  align-items: center
  justify-content: center
  color: var(--tint)
  position: relative

  .Checkbox.disabled &
    border-color: var(--foreground-secondary)
    color: var(--foreground-secondary)

  svg
    opacity: 0
    position: absolute
    top: -4px
    left: -4px
    width: 24px
    height: 24px

  .Checkbox.checked &
    svg
      opacity: 1

.Checkbox__label
  margin-left: 16px
  font-size: 16px
  line-height: 22px
