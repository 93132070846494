.Online__wrapper
  display: flex
  align-items: center
  margin-left: 8px

  &.inPhoto
    position: absolute
    right: -2px
    bottom: -2px
    border: 2px solid var(--background-primary)
    border-radius: 50%
    overflow: hidden

  &:first-child
    margin-left: 0

.Online
  width: 8px
  height: 8px
  flex: 0 0 auto
  border-radius: 4px
  position: relative

  .Online__wrapper.inPhoto &
    border-radius: 0

  svg
    position: absolute
    top: 0
    left: 0

  &.active
    background: #45E688

.Online__label
  margin-left: 8px
  text-overflow: ellipsis
  overflow: hidden
  color: var(--foreground-tertiary)
  font-size: 16px
  line-height: 20px

  &.skipMargin
    margin-left: 0

