.HorizontalScroll
  overflow: hidden

.HorizontalScroll__content
  overflow-y: auto
  padding-bottom: 30px
  margin-bottom: -30px
  display: flex
  touch-action: pan-x
  overflow-scrolling: touch

  &:before, &:after
    content: ''
    flex: 0 0 auto
    width: 16px
    height: 16px
    display: block

.HorizontalScroll__content::-webkit-scrollbar
  display: none
