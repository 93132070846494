.AnimeLists
  display: flex
  flex-direction: column
  align-items: center
  padding: 16px
  text-decoration: none
  color: var(--foreground-primary)

.AnimeLists__photo
  width: 82px
  height: 82px
  border-radius: var(--photo-border-radius)
  background: no-repeat center/cover
  flex: 0 0 auto

.AnimeLists__name
  font-size: 22px
  line-height: 26px
  color: var(--foreground-primary)
  font-weight: bold
  margin-top: 8px

.AnimeLists__label
  margin-top: 24px
  font-size: 14px
  line-height: 18px
  color: var(--foreground-tertiary)

.AnimeLists__item
  background: var(--background-secondary)
  border-radius: 16px
  margin-top: 16px
  padding: 8px 0

.AnimeLists__item__header
  color: var(--foreground-primary)
  text-decoration: none
  cursor: pointer
  display: block

.AnimeLists__item__title_wrap
  display: flex

  .InlineHeader
    flex: 1
    display: flex
    align-items: center
    flex-wrap: wrap

    svg
      color: var(--foreground-tertiary)
      width: 20px
      height: 20px
      margin-left: 4px

.AnimeLists__item_show_all
  flex: 0 0 auto
  margin-left: 16px
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)
  padding: 12px 16px
  display: flex
  align-items: center

  svg
    margin-left: 4px

.AnimeLists__item__description
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)
  padding: 0 16px 8px

.AnimeLists__share
  display: flex
  align-items: center
  justify-content: center
  padding: 16px
