.WriteCell
  display: flex
  align-items: center
  padding: 8px 16px

  .desktop &
    border-radius: 16px

  &:hover, &:active
    background: var(--background-tertiary)

.WriteCell__photo
  width: 48px
  height: 48px
  border-radius: var(--photo-border-radius)
  background: var(--background-tertiary) no-repeat center/cover
  flex: 0 0 auto

.WriteCell__cont
  flex: 1
  margin-left: 16px
  overflow: hidden

.WriteCell__name_wrap
  display: flex
  align-items: center

.WriteCell__name
  font-size: 18px
  line-height: 22px
  font-weight: 800
  color: var(--foreground-primary)
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.WriteCell__arrow
  flex: 0 0 auto
  margin-left: 12px
  width: 16px
  height: 16px
  color: var(--foreground-tertiary)
  margin-right: -6px
