.CommentForm
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  background: var(--background-primary)
  z-index: 1000
  will-change: transform

.CommentForm__content
  display: flex
  align-items: flex-end

.CommentForm__input_wrap
  padding: 8px 0
  box-sizing: border-box
  display: flex
  flex: 1

  textarea
    background: var(--background-secondary)
    border-radius: 20px
    font-size: 17px
    line-height: 24px
    padding: 8px 16px
    border: 0
    resize: none
    outline: none
    color: var(--foreground-primary)
    box-sizing: border-box
    width: 100%

.CommentForm__button
  width: 48px
  height: 48px
  display: flex
  align-items: center
  justify-content: center
  margin: 4px
  color: var(--tint)
  cursor: pointer
  position: relative
  overflow: hidden

  input
    opacity: 0
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 3

.CommentForm__helper
  height: 56px

.CommentForm__reply_to
  display: inline-flex
  padding: 6px 16px 0 16px
  font-size: 16px
  line-height: 18px
  cursor: pointer
  color: var(--foreground-tertiary)

.CommentForm__reply_to__cancel
  width: 18px
  height: 18px
  margin-left: 4px
  display: flex
  align-items: center
  justify-content: center

.CommentForm__sender
  width: 48px
  height: 48px
  margin: 4px 4px 4px 0
  display: flex
  align-items: center
  justify-content: center

.CommentForm__sender__photo
  width: 40px
  height: 40px
  border-radius: 50%
  background: no-repeat center/cover
