.CreatePostForm
  background: var(--background-primary)
  border-radius: 16px
  padding: 12px 16px
  display: flex
  align-items: center

  Input
    flex: 1
    pointer-events: none
    padding: 9px 12px
    height: 44px !important
    min-height: 44px !important
    background: var(--background-secondary)

.CreatePostForm__photo
  width: 42px
  height: 42px
  flex: 0 0 auto
  background: no-repeat center/cover
  border-radius: 12px
  margin-right: 12px
