.InlineHeader
  padding: 10px 16px

.InlineHeader__title
  display: flex
  align-items: center
  font-weight: 800
  font-size: 22px
  line-height: 28px

.InlineHeader__counter
  color: var(--foreground-tertiary)
  margin-left: 8px

.InlineHeader__caption
  margin-top: 4px
  color: var(--foreground-tertiary)
  font-size: 17px
  line-height: 24px
