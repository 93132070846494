.History
  flex: 1
  display: flex
  justify-content: flex-end
  align-items: center
  flex-direction: column
  padding: 16px 0

.History__date
  padding: 9px 32px
  flex: 0 0 auto
  text-align: center
  font-weight: 800
  font-size: 15px
  line-height: 18px
  color: var(--foreground-tertiary)
