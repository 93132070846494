.ptr-dragging .ptr__children
  pointer-events: none !important

.ptr__children, .PullToRefresh
  overflow: initial !important
  flex: 1
  display: flex
  flex-direction: column

.lds-ellipsis div
  background: #fff !important
  opacity: 0.72

.PullToRefresh__label
  color: var(--foreground-tertiary)
  font-size: 14px
  line-height: 18px
  text-align: center
  margin-top: 16px
