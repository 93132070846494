.Message
  padding: 4px 16px 4px 84px
  display: flex
  justify-content: flex-end
  align-items: flex-end
  width: 100%
  box-sizing: border-box

  &.isGroupingBefore
    padding-top: 2px

  &.isGroupingAfter
    padding-bottom: 2px

  .Attachments
    margin: -8px -12px

  &.inbox
    padding-left: 12px
    padding-right: 84px
    justify-content: flex-start

.Message__attachments
  min-width: 200px
  width: 100%
  position: relative

  &:empty
    display: none

  .Message__text + & .Attachments
    margin-top: 6px

.Message__in
  background: var(--tint)
  padding: 7px 12px
  border-radius: 20px
  display: flex
  align-items: flex-end
  justify-content: flex-end
  position: relative
  overflow: hidden

  .Message.isGroupingBefore.inbox &
    border-top-left-radius: 8px

  .Message.isGroupingAfter.inbox &
    border-bottom-left-radius: 8px

  .Message.isGroupingBefore.outbox &
    border-top-right-radius: 8px

  .Message.isGroupingAfter.outbox &
    border-bottom-right-radius: 8px


  .Message.inbox &
    background: var(--background-primary)

.Message__text
  font-size: 17px
  line-height: 22px
  color: var(--background-primary)

  .Linkify
    display: inline

  .Message.inbox &
    color: var(--foreground-primary)

  &:empty
    display: none

  .Message__meta
    position: relative
    right: initial
    bottom: initial
    opacity: 0
    margin-left: 8px

.Message__time
  font-weight: normal
  font-size: 11px
  line-height: 14px
  align-self: flex-end
  display: flex
  align-items: center
  z-index: 3
  white-space: nowrap
  transform: translateZ(0)

.Message__failed
  display: flex
  align-items: center
  height: 36px
  padding: 0 12px
  color: var(--tint-negative)

.Message__meta
  display: inline-flex
  align-items: center
  position: absolute
  bottom: 6px
  right: 12px
  color: rgba(0,0,0,0.72)

  .Message.inbox &
    color: rgba(255,255,255,0.72)

  .Message.withAttach &
    background: rgba(0,0,0,.6)
    border-radius: 9px
    padding: 2px 4px
    color: rgba(255,255,255,0.72)

.Message__send_indicator
  width: 16px
  height: 16px
  margin-left: 2px

