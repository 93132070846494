.ActionSheet
  position: fixed
  z-index: 1002
  top: 0
  left: 0
  width: 100%
  height: 100%
  backdrop-filter: blur(6px)
  background: rgba(0,0,0,0.7)
  display: flex
  align-items: center
  justify-content: center
  padding: 32px 16px
  box-sizing: border-box
  flex-direction: column

.ActionSheet__options
  flex: 1
  overflow-y: auto
  -webkit-overflow-scrolling: touch
  padding: 16px 0
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  box-sizing: border-box

.ActionSheet__option
  font-size: 22px
  line-height: 28px
  font-weight: 800
  padding: 16px
  transform: scale(0.9)
  color: var(--foreground-secondary)
  width: 100%
  box-sizing: border-box
  text-align: center
  cursor: pointer
  position: relative

  &.selected
    transform: scale(1)
    color: var(--foreground-primary)
    pointer-events: none

.ActionSheet__close
  background: var(--constant-light)
  width: 48px
  height: 48px
  border-radius: 50%
  margin-top: 32px
  display: flex
  align-items: center
  justify-content: center
  color: var(--constant-dark)
