.PlayAnime__info
  padding: 16px 16px 8px

.PlayAnime__title
  font-size: 18px
  font-weight: 800
  line-height: 24px

.PlayAnime__nav
  display: flex
  justify-content: space-between
  margin-top: 16px


.PlayAnime__player
  position: relative
  padding-top: 60%

.PlayAnime__player__video
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0

.PlayAnime__filler
  color: var(--foreground-tertiary)
