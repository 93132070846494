.Toast
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 16px
  box-sizing: border-box
  z-index: 1003

  .PopoutWrapper__mask
    background: transparent !important

  .keyboard_shown &.PopoutWrapper--ios
    padding-top: 300px

.Toast__content
  display: flex
  align-items: center
  background: var(--constant-light)
  border-radius: 16px
  color: var(--constant-dark)
  padding: 16px 24px
  font-weight: bold
  font-size: 18px
  line-height: 24px
  text-align: center
  animation: Toast_appear_anim 150ms ease-in-out forwards
  word-break: break-word
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08)

  body.white &
    background: var(--constant-dark)
    color: var(--constant-light)


  .Loader
    margin-right: 12px
    margin-left: -8px


@keyframes Toast__loading_anim
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes Toast_appear_anim
  from
    opacity: 0
  to
    opacity: 1
