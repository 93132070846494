.Select
  position: relative

  select
    width: 100%
    appearance: none
    background: var(--background-primary)
    border: 0
    padding: 12px 16px
    font-size: 18px
    line-height: 24px
    border-radius: 16px
    outline: none
    color: var(--foreground-primary)
    box-sizing: border-box

.Select__chevron
  width: 16px
  height: 16px
  position: absolute
  right: 12px
  top: 50%
  transform: translateY(-50%)
  color: var(--foreground-tertiary)
