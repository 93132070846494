.AnimeList__author
  display: flex
  align-items: center
  margin-top: 16px
  background: var(--background-secondary)
  padding: 12px 16px
  border-radius: 16px
  text-decoration: none
  color: var(--foreground-primary)

.AnimeList__author__photo
  flex: 0 0 auto
  width: 48px
  height: 48px
  border-radius: var(--photo-border-radius)
  background: no-repeat center/cover

.AnimeList__author__cont
  margin-left: 16px

.AnimeList__author__name
  font-size: 16px
  line-height: 20px
  font-weight: bold

.AnimeList__author__label
  font-size: 14px
  line-height: 18px
  color: var(--foreground-tertiary)
  margin-top: 4px

.AnimeList__info
  padding: 16px

.AnimeList__title
  font-size: 24px
  line-height: 28px
  font-weight: bold
  display: flex
  align-items: center
  flex-wrap: wrap

  svg
    margin-left: 4px
    color: var(--foreground-tertiary)
    width: 20px
    height: 20px

.AnimeList__description
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)
  margin-top: 8px
