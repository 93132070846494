.NotFound
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 64px 32px

.NotFound__title
  font-size: 32px
  font-weight: bold
  color: var(--foreground-primary)

.NotFound__caption
  margin-top: 24px
  font-size: 18px
  line-height: 26px
  color: var(--foreground-tertiary)
  text-align: center
