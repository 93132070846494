.RatingBlock
  margin: 8px 16px 0
  padding: 14px 16px
  box-sizing: border-box
  border-radius: 12px
  display: block
  color: var(--foreground-primary)
  background: var(--background-secondary)

.RatingBlock__link
  text-decoration: none
  color: var(--foreground-primary)

.RatingBlock__info
  display: flex
  align-items: center
  justify-content: space-between

.RatingBlock__info__label
  font-weight: bold
  font-size: 16px
  line-height: 20px

.RatingBlock__info__count
  display: flex
  align-items: center
  font-weight: bold
  font-size: 16px
  line-height: 20px

  svg
    margin-right: 2px

.RatingBlock__progress
  margin-top: 12px
  background: var(--background-quaternary)
  border-radius: 4px

.RatingBlock__progress__indicator
  height: 8px
  border-radius: 4px

.RatingBlock__level_info
  margin-top: 12px
  color: var(--foreground-secondary)
  font-size: 14px
  line-height: 18px

.RatingColor
  &.red
    color: var(--accent-color-red)

  &.orange
    color: var(--accent-color-orange)

  &.green
    color: var(--accent-color-green)

  &.cyan
    color: var(--accent-color-cyan)

  &.purple
    color: var(--accent-color-purple)

.RatingBackground
  &.red
    background: var(--accent-gradient-red)

  &.orange
    background: var(--accent-gradient-orange)

  &.green
    background: var(--accent-gradient-green)

  &.cyan
    background: var(--accent-gradient-cyan)

  &.purple
    background: var(--accent-gradient-purple)

.RatingBlock__share
  font-size: 14px
  line-height: 18px
  margin-top: 8px

  .Separator
    margin-bottom: 8px

.RatingBlock__share_btn
  font-weight: 500
  color: var(--accent)
  margin-top: 4px
