.Registration__title
  font-weight: 800
  font-size: 24px
  line-height: 32px
  padding: 0 32px 32px
  text-align: center

.Registration__step3
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 32px 16px
  box-sizing: border-box
  flex: 1

.Registration__photo_wrap
  width: 168px
  height: 168px
  border-radius: 50%
  background: var(--background-primary) no-repeat center/cover
  position: relative

  input
    position: absolute
    z-index: 2
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0

.Registration__photo__loader
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  z-index: 1

  &.add_icon
    svg
      transform: scale(1.2)

.Registration__photo__remove_wrap
  height: 36px
  margin-top: 32px
