.CreateAccountPopover__helper
  flex: 0 0 auto
  height: 96px

@keyframes CreateAccountPopover__anim
  from
    transform: translateY(100%)

  to
    transform: translateY(0%)

.CreateAccountPopover
  position: fixed
  bottom: 0
  left: 0
  right: 0
  display: flex
  justify-content: center
  z-index: 100
  text-decoration: none
  color: #fff
  padding: 0 16px 16px
  box-sizing: border-box
  animation: CreateAccountPopover__anim 600ms ease-in-out forwards
  animation-delay: 1.5s
  transform: translateY(100%)

.CreateAccountPopover__content
  border-radius: 12px
  padding: 12px
  background: var(--tint)
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 0 24px rgba(0, 0, 0, 0.16)
  display: flex
  align-items: center

.CreateAccountPopover__info
  flex: 1
  margin-right: 16px

.CreateAccountPopover__title
  font-size: 16px
  font-weight: bold
  line-height: 20px

.CreateAccountPopover__caption
  font-size: 14px
  line-height: 18px
  color: #fff
  opacity: 0.82
