.Profile
  padding-bottom: 16px

  .CreatePostForm
    margin-top: 16px

.Profile__info_block
  background: var(--background-primary)
  border-radius: 0 0 16px 16px

  .Button.secondary
    background: var(--background-secondary)

.Profile__common_info
  display: flex
  padding: 0 16px 16px

.Profile__common_info__photo
  width: 82px
  height: 82px
  background: no-repeat center/cover
  border-radius: 12px
  flex: 0 0 auto

.Profile__common_info__cont
  margin-left: 16px
  overflow: hidden

.Profile__common_info__name_wrap
  display: flex
  align-items: center
  overflow: hidden

.Profile__common_info__name
  font-weight: 800
  font-size: 20px
  line-height: 28px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.Profile__common_info__status
  color: var(--foreground-primary)
  font-size: 16px
  line-height: 22px
  margin-top: 4px
  display: flex
  align-items: center

.Profile__common_info__status__chevron
  margin-left: 8px
  flex: 0 0 auto

.Profile__common_info__status__icon
  flex: 0 0 auto
  width: 22px
  height: 22px
  color: var(--foreground-tertiary)
  margin-right: 8px

.Profile__info
  margin-top: 16px
  padding: 0 16px

.Profile__info__item
  display: flex
  color: var(--foreground-secondary)
  margin-top: 8px

  &:first-child
    margin-top: 0

.Profile__info__item__label
  font-weight: 500

.Profile__info__item__value
  margin-left: 8px

.Profile__counters
  display: flex
  padding: 24px 16px 16px

.Profile__counters__item
  flex: 1
  color: var(--foreground-primary)
  text-align: center
  background: var(--background-secondary)
  padding: 12px 0
  border-radius: 16px
  margin-left: 12px

  &:first-child
    margin-left: 0

.Profile__counters__item_count
  font-weight: 800
  font-size: 20px
  line-height: 24px

.Profile__counters__item_label
  margin-top: 6px
  font-size: 12px
  line-height: 14px

.Profile__follow_wrap
  padding: 0 16px
  margin-top: 16px
  margin-bottom: -8px

.Profile__upload_ava_input
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0

.Profile__link
  background: var(--background-secondary)
  border-radius: 16px
  margin-top: 8px
  padding: 10px 16px
  display: flex
  align-items: center
  cursor: pointer

.Profile__link__cont
  flex: 1

.Profile__link__title
  font-size: 15px
  line-height: 20px

.Profile__link__caption
  font-size: 13px
  line-height: 16px
  color: var(--foreground-secondary)

.Profile__link__icon
  flex: 0 0 auto
  margin-left: 16px
  color: var(--foreground-tertiary)
  width: 28px
  height: 28px

.Profile__buttons
  padding-bottom: 16px

  .CellButton__in
    background: var(--background-secondary)

  &:empty
    display: none
