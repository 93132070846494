.BottomBar
  position: relative
  flex: 0 0 auto

.BottomBar__in
  padding: 16px
  background: #fff
  background: var(--background-zero)
  bottom: 0
  left: 0

  .desktop &
    background: var(--background-primary)

.BottomBar__content
  display: flex
  align-items: flex-start
  width: 100%

  .desktop &
    justify-content: center
    max-width: 360px
    margin: 0 auto

    .Button.stretched
      flex: 0 0 auto
      width: auto

  .Button
    margin-left: 16px

    &:first-child
      margin-left: 0

  .BottomBar.column &
    flex-direction: column

    .Button
      width: 100%
      box-sizing: border-box
      margin-left: 0
      margin-top: 16px

      &:first-child
        margin-top: 0

.desktop .BottomBar__in.fixed
  left: 72px
  width: calc(100% - 72px)

.BottomBar__in
  width: 100%
  box-sizing: border-box
  padding: 16px
  padding-bottom: unquote('max(env(safe-area-inset-bottom), 16px)')

  .View--ios:focus-within &
    padding-bottom: 16px

.View--ios:focus-within .Panel__in
  padding-bottom: 16px !important
