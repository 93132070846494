.Verified
  width: 16px
  height: 16px
  flex: 0 0 auto
  margin-left: 8px
  line-height: 16px

  svg
    margin: 0 !important

  &.large, &.large svg
    line-height: 22px
    width: 22px
    height: 22px
