.Rating__info
  background: var(--background-primary)
  border-radius: 16px
  padding: 16px 0
  margin-bottom: 16px

.Rating__description
  font-size: 16px
  line-height: 22px
  color: var(--foreground-primary)
  padding: 0 16px

.Rating__give_buttons
  background: var(--background-primary)
  border-radius: 16px
  margin-bottom: 16px
  padding: 16px 0

  .CellButton__in
    background: var(--background-secondary)

.Rating__levels
  background: var(--background-primary)
  border-radius: 16px
  margin-bottom: 16px

  .Form__item__column
    margin-left: 0 !important

  .Form__item
    flex: 1

  .Form__item__title
    font-weight: normal
    font-size: 16px
    line-height: 18px
    margin-bottom: 0

.Rating__level
  height: 14px
  border-radius: 7px
  flex: 0 0 auto !important
  width: 100%
  margin-top: 8px
