.Search
  padding-bottom: 16px

  .Anime__search
    padding: 0

.Search__tabs
  padding-bottom: 8px

.Search__more_btn
  display: flex
  text-decoration: none
  color: var(--foreground-tertiary)
  background: var(--background-primary)
  height: 48px
  align-items: center
  justify-content: center
  border-radius: 16px
  font-size: 16px
  line-height: 20px
  margin: 8px 16px 16px

  svg
    margin-left: 4px
    width: 16px
    height: 16px
