.Group
  padding-bottom: 16px

  .Profile__follow_wrap
    margin-bottom: 0

.Group__info_row
  margin-bottom: 12px
  padding: 0 16px

  &:first-child
    margin-top: 0

.Group__info_label
  font-size: 14px
  line-height: 18px
  color: var(--foreground-secondary)

.Group__info_cont
  margin-top: 4px
  font-size: 17px
  line-height: 23px

  a
    text-decoration: underline
    color: var(--foreground-primary)


.Group__description
  overflow: hidden
  -webkit-line-clamp: 5
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  max-lines: 5
  display: -webkit-box
  color: var(--foreground-primary)
  position: relative

  &.expanded
    display: block

.Group__description__expand_btn
  position: absolute
  background-color: var(--background-primary)
  top: 88px
  width: 100%
  height: 22px
  font-weight: 500
  color: var(--foreground-tertiary)
  cursor: pointer

  .Group__description.expanded &
    top: 0
    position: static

.Group__followers
  padding: 12px 0
  text-align: center
  font-size: 14px
  line-height: 18px
  color: var(--foreground-tertiary)
  cursor: pointer
  display: block

.Group__scheduled_posts
  background: var(--background-secondary)
  padding: 16px
  border-radius: 16px
  color: var(--foreground-primary)
  text-align: center
  cursor: pointer
  font-weight: 600
  font-size: 18px
  line-height: 28px
  margin-top: 16px
  display: flex
  align-items: center
  justify-content: center
  transition: background 150ms ease-in-out

  svg
    margin-right: 8px

  &:hover, &:active
    background: var(--background-tertiary)
