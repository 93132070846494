.ViewVideo
  position: relative
  width: 100%
  height: 100%
  flex: 1
  min-height: 450px

  video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
