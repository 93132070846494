.Form
  width: 100%
  box-sizing: border-box
  padding: 8px 0

.Form__item
  padding: 8px 16px

  .Checkbox
    padding: 8px 0 !important

  &.skipPadding
    padding-left: 0
    padding-right: 0

  &:first-child
    margin-top: 0

.Form__item__column
  flex: 1
  margin-left: 16px
  display: flex
  overflow: hidden

  &:first-child
    margin-left: 0

.Form__item__title
  font-weight: 800
  font-size: 18px
  line-height: 22px
  margin-bottom: 8px

.Form__item__cont
  display: flex
  flex-direction: column

  &>div
    flex: 1

  .Input
    width: 100%

.Form__item__description
  font-size: 14px
  line-height: 18px
  color: var(--foreground-tertiary)
  margin-top: 8px
