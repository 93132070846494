.SendForm
  position: fixed
  bottom: 0
  right: 0
  left: 0
  padding: 0 4px
  box-sizing: border-box
  display: flex
  justify-content: center
  z-index: 100
  background: var(--background-zero)
  padding-bottom: env(safe-area-inset-bottom)

  .desktop &
    left: var(--navbar-width)

.SendForm__cont_wrap
  width: 100%
  max-width: var(--max-width)

.SendForm__cont
  display: flex
  padding: 4px
  align-items: flex-end
  width: 100%

.SendForm__button
  width: 48px
  height: 48px
  flex: 0 0 auto
  display: flex
  align-items: center
  justify-content: center
  color: var(--tint)
  cursor: pointer
  transition: opacity 150ms ease-in-out
  overflow: hidden
  position: relative

  &:hover, &:active
    opacity: var(--active-opaicty)

  input
    opacity: 0
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 3

.SendForm__input_wrap
  padding: 4px
  flex: 1
  box-sizing: border-box
  display: flex

  .Input
    width: 100%
    border-radius: 20px

.SendForm__helper
  flex: 0 0 auto
  height: 56px
  width: 100%

.SendForm__banned
  height: 56px
  display: flex
  align-items: center
  justify-content: center
  padding: 0 16px
  text-align: center
  font-size: 16px
  line-height: 20px
  font-weight: 800
