.NotifyRequest
  padding: 0 16px 16px

.NotifyRequest__in
  background: var(--background-tertiary)
  border-radius: 16px
  padding: 13px 16px
  display: flex
  align-items: center

.NotifyRequest__cont
  flex: 1

.NotifyRequest__title
  font-weight: bold
  font-size: 17px
  line-height: 24px

.NotifyRequest__caption
  margin-top: 2px
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)
