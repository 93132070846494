.Header
  display: flex
  align-items: center
  justify-content: center
  background: var(--background-zero)
  padding-top: var(safe-area-inset-top)
  width: 100%
  height: 56px
  max-width: var(--max-width)
  margin: 0 auto
  flex: 0 0 auto
  position: relative
  z-index: 100

  &.isSecondaryBg
    background: var(--background-primary)

  &.isTransparent
    background: linear-gradient(180deg, var(--background-primary) 0%, rgba(21, 28, 41, 0) 100%)
    position: absolute

  &.isTransparent.isSecondaryBg
    background: linear-gradient(180deg, var(--background-primary) 0%, rgba(21, 28, 41, 0) 100%)

.Header__helper
  width: 100%
  height: 56px
  flex: 0 0 auto

  &.isSecondaryBg
    background: var(--background-primary)

.Header__title
  font-weight: 800
  font-size: 20px
  line-height: 28px
  color: var(--foreground-primary)
  text-align: center
  padding: 0 48px + 4px + 12px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

  .Header.isSearch &
    flex: 1
    padding-left: 16px
    padding-right: 16px

  .Header.isSearch.hasBack &
    padding-left: 56px

  .desktop .Header.isSearch &
    padding-left: 16px

.Header__back, .Header__right
  position: absolute
  top: 4px
  width: 48px
  height: 48px
  display: flex
  align-items: center
  justify-content: center
  color: var(--tint)
  cursor: pointer
  transition: opacity 150ms ease-in-out

  &:active
    opacity: var(--active-opaicty)

.Header__back
  left: 4px

.Header__right
  right: 4px

