.AnimeItem
  padding: 8px 16px
  display: flex

.AnimeItem__poster
  background: var(--background-tertiary) no-repeat center/cover
  width: 100px
  height: 136px
  border-radius: 12px
  flex: 0 0 auto

.AnimeItem__cont
  margin-left: 16px
  overflow: hidden
  max-height: 136px
  display: flex
  flex-direction: column

.AnimeItem__title
  font-size: 18px
  line-height: 22px
  font-weight: 800
  flex: 0 0 auto
  overflow: hidden
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  max-lines: 2
  display: -webkit-box
  color: var(--foreground-primary)

.AnimeItem__description
  font-size: 16px
  line-height: 20px
  margin-top: 12px
  color: var(--foreground-tertiary)
  overflow: hidden
  flex: 1
  position: relative

  &:after
    content: ''
    display: block
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 25px
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
