.FeedNotification
  display: flex
  padding: 16px
  position: relative
  background: var(--background-primary)
  margin-top: -16px
  border-radius: 0 0 16px 16px
  margin-bottom: 16px

.FeedNotification__icon
  flex: 0 0 auto
  width: 42px
  height: 42px
  border-radius: 12px
  background: var(--background-secondary) no-repeat center/cover

.FeedNotification__cont
  margin-left: 16px

.FeedNotification__title
  font-weight: bold
  font-size: 15px
  line-height: 17px

.FeedNotification__description
  color: var(--foreground-tertiary)
  font-size: 14px
  line-height: 16px
  margin-top: 4px

.FeedNotification__action
  display: flex
  margin-top: 12px

  .Button
    margin-left: 8px

    &:first-child
      margin-left: 0
