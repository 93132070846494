.NewPost
  display: flex
  flex-direction: column
  padding: 16px 16px 0 16px

.NewPost__attachments
  margin: 8px -6px 0
  display: flex
  flex-wrap: wrap

  .Button
    margin-left: 6px
    margin-right: 6px
    margin-top: 8px

.NewPost__attachments__photo_wrap
  position: relative
  overflow: hidden

  .Button
    z-index: 1

  input
    position: absolute
    z-index: 2
    opacity: 0
    top: 0
    left: 0
    width: 100%
    height: 100%

.NewPost__button_wrap
  margin-top: 16px
