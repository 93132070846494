.Anime__cards a
  margin-left: 8px

  &:first-child
    margin-left: 0

.AnimeCard
  flex: 0 0 auto

.AnimeCard__poster
  width: 100px
  height: 136px
  background: var(--background-tertiary) no-repeat center/cover
  border-radius: 12px
