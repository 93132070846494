.ViewAnime__title
  font-size: 18px
  line-height: 22px
  color: var(--foreground-primary)
  font-weight: 500

.ViewAnime__orig_name
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)
  padding: 0 16px 8px

.ViewAnime__continue
  padding: 8px 16px 16px

.ViewAnime__info
  display: flex
  padding: 0 16px 16px
  margin-top: 8px

.ViewAnime__info__poster
  width: 100px
  height: 136px
  background: var(--background-tertiary) no-repeat center/cover
  border-radius: 12px
  flex: 0 0 auto

.ViewAnime__info_cont
  margin-left: 16px

.ViewAnime__info__rows
  margin-top: 8px

.ViewAnime__info__row
  display: flex
  padding: 4px 0
  font-size: 14px
  line-height: 18px

.ViewAnime__info__row__label
  flex: 0 0 auto
  font-weight: 500
  color: var(--foreground-tertiary)

.ViewAnime__info__row__value
  margin-left: 8px

.ViewAnime__description_wrap
  padding: 8px 16px 0

  .Button
    justify-content: flex-start
    padding-left: 0

  .Button__text
    padding: 0 !important

.ViewAnime__description
  font-size: 16px
  line-height: 22px
  overflow: hidden
  -webkit-line-clamp: 4
  -webkit-box-orient: vertical
  text-overflow: ellipsis
  max-lines: 4
  display: -webkit-box
  color: var(--foreground-secondary)

  &.expanded
    display: block

.ViewAnime__season_select
  display: flex
  align-items: center

  svg
    margin-left: 8px
    color: var(--foreground-tertiary)

.ViewAnime__like_wrap
  padding: 8px 16px
  display: flex
  justify-content: flex-start

  .Button
    margin-left: 12px

    &:first-child
      margin-left: 0

  span
    opacity: 0.7
    margin-left: 6px
