.TestsResult__score
  font-size: 62px
  font-weight: bold
  line-height: 70px
  margin-bottom: 16px

  &.low
    color: darkred

  &.medium
    color: orange

  &.high
    color: green

.TestsResult__score__label
  font-size: 18px
  line-height: 22px
  color: var(--foreground-secondary)

.TestsResult__separator
  height: 1px
  background-color: var(--background-quaternary)
  margin: 32px 0

.TestsResult__test_name
  font-size: 24px
  line-height: 26px
  font-weight: bold
  margin-bottom: 16px

.TestsResult__title
  font-size: 22px
  line-height: 26px
  font-weight: bold
  margin-bottom: 12px