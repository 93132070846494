.Loader
  width: 24px
  height: 24px
  flex: 0 0 auto
  position: relative

  &:after
    display: block
    content: ''
    background: url('../../../assets/28/loader_28_white.png') no-repeat center/contain
    width: 28px
    height: 28px
    position: absolute
    top: -2px
    left: -2px
    animation: Toast__loading_anim 1s linear forwards infinite

  &.gray:after
    background-image: url('../../../assets/28/loader_28.png')

  body[scheme="space_gray"] &.gray:after
    background-image: url('../../../assets/28/loader_28_white.png')

  body[scheme="light"] &:after
    background-image: url('../../../assets/28/loader_28.png')

.Loader__wrap
  display: flex
  align-items: center
  justify-content: center
  pointer-events: none
  flex: 1
  height: 100%

  .desktop.modal &
    position: static
    height: 250px
    flex: 0 0 auto

