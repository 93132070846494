.UserCell
  display: flex
  align-items: center
  padding: 8px 16px

  .desktop &
    border-radius: 16px

  &:hover, &:active
    background: var(--background-tertiary)

.UserCell__photo
  width: 52px
  height: 52px
  border-radius: var(--photo-border-radius)
  background: var(--background-tertiary) no-repeat center/cover
  flex: 0 0 auto
  position: relative

.UserCell__cont
  flex: 1
  margin-left: 16px
  overflow: hidden

.UserCell__name_wrap
  display: flex
  align-items: center

.UserCell__name
  font-size: 18px
  line-height: 22px
  font-weight: 800
  color: var(--foreground-primary)
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.UserCell__followers
  margin-top: 6px
  font-size: 16px
  line-height: 20px
  color: var(--foreground-tertiary)

.UserCell__follow
  margin-left: 16px
