.AttachPickerManager
  margin-top: 16px

  &.mini
    margin-top: 8px

  &:empty
    margin-top: 0

.AttachPickerManager__photo__controls
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.AttachPickerManager__photo__remove
  position: absolute
  top: 6px
  right: 6px
  width: 24px
  height: 24px
  border-radius: 50%
  background: rgba(0,0,0, 0.72)
  display: flex
  align-items: center
  justify-content: center
  color: var(--foreground-tertiary)
  z-index: 3

  svg
    width: 12px
    height: 12px

.AttachPickerManager__photo__loader
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  background: rgba(0,0,0,0.42)
  z-index: 2

.AttachPickerManager__mini_photos
  display: flex
  flex-wrap: wrap
  padding: 0 12px

.AttachPickerManager__mini_photo
  padding: 4px
  display: flex
  width: 20%
  box-sizing: border-box

.AttachPickerManager__mini_photo__image
  flex: 1
  position: relative
  background: no-repeat center/cover
  border-radius: 16px

  &:after
    content: ''
    display: flex
    padding-top: 100%

.AttachPickerManager__link_loader
  display: flex
  height: 64px
  align-items: center
  justify-content: center

.AttachPickerManager__link_header
  display: flex
  margin-bottom: 8px
  align-items: center

.AttachPickerManager__link_header_title
  font-weight: 800
  font-size: 16px
  line-height: 20px
  flex: 1

.AttachPickerManager__link_header_remove
  flex: 0 0 auto
  color: var(--foreground-tertiary)
  display: flex
  align-items: center
  justify-content: center
  width: 24px
  height: 24px
  margin-top: -4px
  margin-right: -4px
  cursor: pointer
  transition: color 150ms ease-in-out

  &:hover
    color: var(--foreground-primary)

.AttachPickerManager__timer
  margin-top: 12px

  .Input
    width: 100%

  .Input::-webkit-calendar-picker-indicator
    filter: invert(1)
