.ViewPost
  padding-bottom: 16px
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px)

  .Post
    margin-top: 0

.ViewPost__comments
  margin-top: 10px
  height: 48px
  display: flex
  align-items: center
  padding: 0 16px
  font-weight: 800
  font-size: 17px
  line-height: 22px
  color: var(--foreground-primary)
