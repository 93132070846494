.All
  padding: 16px

.All__item
  margin-top: 4px

  &:first-child
    margin-top: 0

  a
    color: var(--foreground-primary)
    text-decoration: none

    &:hover
      text-decoration: underline

.All__pagination
  margin-top: 16px
  padding: 0 16px

  a
    color: var(--foreground-primary)
    font-size: 16px
    display: inline-block
    padding: 4px

    &.active
      color: var(--tint)

    &:hover
      text-decoration: underline

.All__head
  display: flex
  padding: 0 16px

  a
    color: var(--foreground-primary)
    font-size: 16px
    line-height: 20px
    text-decoration: none
    padding: 8px
