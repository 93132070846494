.Chat__header_name_wrap
  display: flex
  align-items: center
  justify-content: center

.Chat__header_name
  font-size: 18px
  line-height: 22px
  font-weight: 800
  color: var(--foreground-primary)
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.Chat__header_online
  font-weight: normal
  margin-top: 2px
  display: flex
  justify-content: center

  .Online
    display: none

  .Online__label
    margin-left: 0
    font-size: 14px
    line-height: 18px
