.Domain__message
  font-size: 16px
  line-height: 20px
  text-align: center
  padding: 16px
  color: var(--foreground-tertiary)

  a
    color: #fff
    font-weight: bold

.Domain__input
  position: relative

  .Input
    padding-left: 92px + 16px + 1px

.Domain__host
  color: var(--foreground-secondary)
  position: absolute
  left: 16px
  top: 50%
  transform: translateY(-50%)
  font-size: 18px
  line-height: 24px
  pointer-events: none
  z-index: 2

.Domain__error
  color: var(--tint-negative)
