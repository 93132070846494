.ScrollView
  flex: 1
  overflow-x: hidden
  overflow-y: auto
  display: flex
  flex-direction: column

.ReactVirtualized__List
  touch-action: pan-y
  overflow-scrolling: touch

.ScrollView::-webkit-scrollbar
  display: none

.ReactVirtualized__List::-webkit-scrollbar
  display: none
