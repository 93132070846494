.Placeholder
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  min-height: 300px
  box-sizing: border-box
  padding: 62px 32px

  &.centered
    padding: 32px
    box-sizing: border-box
    flex: 1

.Placeholder__icon
  margin-bottom: 24px

  &, svg
    width: 56px
    height: 56px


.Placeholder__title
  font-weight: 800
  font-size: 22px
  line-height: 28px
  margin-bottom: 12px
  text-align: center

.Placeholder__text
  font-size: 17px
  line-height: 22px
  color: var(--foreground-tertiary)
  text-align: center

  a
    color: var(--tint)
    text-decoration: underline

.Placeholder__actions
  margin-top: 24px
  flex: 0 0 auto
