.Invite__buttons_wrap
  margin-top: 24px

.Invite__buttons
  display: flex

.Invite__button
  flex: 0 0 auto
  display: flex
  flex-direction: column
  align-items: center
  font-weight: bold
  font-size: 15px
  line-height: 17px
  padding: 12px
  text-align: center
  text-decoration: none
  color: var(--foreground-primary)

  &:before
    content: ''
    display: block
    width: 62px
    height: 62px
    background: center/contain no-repeat
    border-radius: 16px
    margin: 0 auto 12px

  &.telegram:before
    background-image: url('../../assets/72/ic-telegram-72.svg')

  &.vk:before
    background-image: url('../../assets/72/ic-vk-72.svg')

  &.insta:before
    background-image: url('../../assets/72/ic-instagram-72.svg')

  &.fb:before
    background-image: url('../../assets/72/ic-facebook-72.svg')

  &.twitter:before
    background-image: url('../../assets/twitter.jpg')

.Invite__link_wrap
  background: var(--background-primary)
  border-radius: 16px
  padding: 16px
  display: flex
  flex-direction: column
  align-items: center

.Invite__link_title
  font-weight: bold
  font-size: 18px
  line-height: 22px
  text-align: left
  width: 100%

.Invite__link_description
  font-size: 16px
  line-height: 22px
  margin-top: 4px
  text-align: left

  a
    color: var(--foreground-primary)
    text-decoration: underline

.Invite__link_description__warn
  font-size: 14px
  line-height: 18px
  color: var(--foreground-tertiary)
  margin-top: 8px


.Invite__link_label
  color: var(--foreground-tertiary)
  font-size: 15px
  line-height: 17px
  margin-top: 24px

.Invite__link
  background: var(--background-tertiary)
  padding: 10px 16px
  font-size: 18px
  line-height: 22px
  font-weight: bold
  border-radius: 12px
  margin-top: 6px
  margin-bottom: 16px
