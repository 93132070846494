.Comment
  background: var(--background-primary)
  border-radius: 16px
  padding: 16px
  display: flex
  margin-top: 8px

  &:first-child
    margin-top: 0

  .Attachments
    margin-top: 4px
    margin-bottom: 8px

  .Comment
    margin-top: 8px
    padding: 0

    .Comment__name, .Comment__name__reply
      font-size: 13px
      line-height: 15px

    .Comment__name__reply
      margin-left: 4px

    .Verified
      width: 15px
      height: 15px
      margin-left: 4px

    .Comment__photo
      width: 24px
      height: 24px

    .Comment__content
      margin-left: 8px


.Comment__photo
  width: 32px
  height: 32px
  border-radius: 12px
  background: no-repeat center/cover
  flex: 0 0 auto
  display: block
  position: relative

.Comment__content
  margin-left: 12px
  flex: 1
  overflow: hidden

.Comment__name_wrap
  display: flex
  align-items: center
  flex-wrap: nowrap

.Comment__name
  font-size: 15px
  line-height: 20px
  color: var(--foreground-secondary)
  font-weight: 800
  white-space: nowrap
  overflow: hidden

  &:hover
    text-decoration: underline

.Comment__name__reply
  display: flex
  font-size: 15px
  line-height: 18px
  margin-left: 6px
  color: var(--foreground-tertiary)
  white-space: nowrap
  overflow: hidden

.Comment__name__reply__user
  font-weight: bold
  color: var(--foreground-tertiary)
  overflow: hidden
  text-overflow: ellipsis

  &:hover
    text-decoration: underline

.Comment__text
  margin-top: 2px
  font-size: 15px
  line-height: 20px
  user-select: text

.Comment__footer
  display: flex
  align-items: center
  justify-content: space-between

.Comment__footer__group
  display: flex
  align-items: center
  margin-top: 2px

.Comment__reply
  font-weight: bold
  color: var(--foreground-tertiary)
  line-height: 15px
  font-size: 13px
  margin-left: 8px
  cursor: pointer

  &:hover
    text-decoration: underline

.Comment__date
  font-size: 13px
  line-height: 15px
  color: var(--foreground-tertiary)

.Comment__like
  display: flex
  color: var(--foreground-tertiary)
  padding: 8px
  margin: -8px
  cursor: pointer
  align-items: center

  &.liked
    color: var(--tint-negative)

.Comment__like_icon
  flex: 0 0 auto

  &, svg
    width: 16px
    height: 16px

.Comment__like__count
  font-weight: bold
  font-size: 14px
  margin-left: 4px

.Comment__more
  width: 38px
  height: 38px
  display: flex
  align-items: center
  justify-content: center
  margin: -9px -12px -9px auto
  transition: opacity 150ms ease-in-out
  color: var(--foreground-tertiary)

  svg
    transform: scale(0.8)

  &:active
    opacity: 0.7

.Comment__deleted
  display: flex
  flex-direction: column

.Comment__deleted__label
  font-size: 16px
  line-height: 22px
  color: var(--foreground-tertiary)
  flex: 1
  margin-right: 16px

.Comment__deleted__actions
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-left: -12px

  .Button
    margin-top: 10px

.Comment__replies_button
  padding: 8px
  font-size: 14px
  line-height: 18px
  font-weight: bold
  display: flex
  align-content: center
  color: var(--foreground-tertiary)
  margin: 0 -8px -8px
  cursor: pointer

  svg
    margin-right: 4px
    transform: scale(0.8)

  &.hide svg
    transform: scaleY(-1) scale(0.8)
    margin-left: 4px
    margin-right: 0

.Comment__replies_actions
  display: flex
  justify-content: space-between

.Comment__replies_loading
  display: flex
  align-items: center
  height: 34px
  margin-bottom: -8px

.Comment__replies_loading__in
  transform: scale(0.8)
