.PhoneInput
  display: flex
  padding: 0 16px !important
  align-items: center

  &, input
    height: 48px
    font-size: 18px
    line-height: 24px
    border-radius: 16px
    color: var(--foreground-primary)

  input
    border: 0
    outline: none
    flex: 1
    margin-left: 4px
    background: transparent

.PhoneInput__country_code
  flex: 0 0 auto
  background: var(--background-secondary)
  border: 0
  color: #fff
  font-size: 18px
  line-height: 24px
  outline: none
  padding: 0 2px
  appearance: none
  height: 28px
  border-radius: 8px
