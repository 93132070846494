.ExternalVideo__iframe_wrap
  width: 100%
  padding-top: 60%
  position: relative

.ExternalVideo__iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: 0
  background: no-repeat center/cover
  display: flex
  align-items: center
  justify-content: center

.ExternalVideo__play_btn
  width: 102px
  height: 102px
  border-radius: 50%
  background: rgba(0,0,0,0.6)
  display: flex
  align-items: center
  justify-content: center

  svg
    width: 62px
    height: 62px

.ExternalVideo__info
  margin-top: 12px
  padding: 0 16px

.ExternalVideo__title
  font-size: 18px
  line-height: 22px
  font-weight: bold
  color: var(--foreground-primary)

  &:hover
    text-decoration: underline

.ExternalVideo__description
  font-size: 15px
  line-height: 19px
  margin-top: 4px
  color: var(--foreground-tertiary)

.ExternalVideo__platform
  position: absolute
  bottom: 8px
  right: 16px
  color: var(--foreground-primary)
  background: rgba(0,0,0,0.6)
  padding: 4px 10px
  font-size: 14px
  line-height: 16px
  border-radius: 6px
