.CreateAnimeList__search
  min-height: 150px
  display: flex
  flex-direction: column
  position: relative
  padding: 16px 0

  .Loader__wrap, .Placeholder
    position: relative
    flex: 1
    padding: 0 32px

.CreateAnimeList__item
  display: flex
  align-items: center
  padding: 8px 0
  cursor: pointer

.CreateAnimeList__item__name
  font-weight: bold
  font-size: 16px
  line-height: 20px
  flex: 1

.CreateAnimeList__item__btn
  margin-left: 16px
  flex: 0 0 auto
