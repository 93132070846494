.Promo__header
  display: flex
  flex-direction: column
  align-items: center
  padding: 32px
  min-height: 400px
  justify-content: center
  position: relative

.Promo__header__name
  font-weight: 800
  font-size: 20px
  line-height: 28px
  color: var(--foreground-tertiary)
  flex: 0 0 auto

.Promo__header__title
  font-weight: 800
  font-size: 36px
  line-height: 44px
  text-align: center
  margin-top: 24px

.Promo__header__action
  margin-top: 24px

@keyframes Promo__circle1
  0%
    transform: translate(0px, 0px)

  50%
    transform: translate(10px, 5px)

  100%
    transform: translate(0px, 0px)

@keyframes Promo__circle2
  0%
    transform: translate(0px, 0px)

  50%
    transform: translate(-5px, -10px)

  100%
    transform: translate(0px, 0px)

.Promo__header__circle
  position: absolute
  border-radius: 50%
  background: linear-gradient(135deg, #99CC66 0%, #56BF56 100%)
  height: 180px
  width: 180px
  opacity: 0.42

  &.top
    top: -100px
    right: -80px
    animation: Promo__circle1 4s ease infinite

  &.bottom
    bottom: -30px
    left: -80px
    height: 130px
    width: 130px
    animation: Promo__circle2 3s ease infinite

.Promo__features
  padding: 32px 0 0

.Promo__features__item
  padding: 16px
  border-radius: 16px
  background: var(--background-primary)
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  margin-top: 16px

.Promo__features__item__icon
  flex: 0 0 auto
  color: var(--foreground-tertiary)

  &, svg
    width: 48px
    height: 48px

.Promo__features__item__title
  font-size: 22px
  line-height: 26px
  font-weight: bold
  margin-top: 16px

.Promo__features__item__caption
  margin-top: 4px
  font-size: 18px
  line-height: 22px
  color: var(--foreground-tertiary)
