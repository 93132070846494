.Post__repost
  margin-top: 16px

  .Post
    padding-bottom: 0
    margin-top: 0
    border-radius: 0

  .Post__footer, .Post__header__more, .Post__likes
    display: none

  .LinkAttach__type
    padding: 0 16px

.Post
  border-radius: 16px
  background: var(--background-primary)
  margin-top: 16px
  padding: 16px 0
  overflow: hidden

.Post__header
  display: flex
  align-items: center
  padding: 0 16px

.Post__header__photo
  flex: 0 0 auto
  width: 48px
  height: 48px
  border-radius: 12px
  background: no-repeat center/cover
  position: relative

.Post__header__content
  margin-left: 12px
  text-decoration: none

.Post__header__name_wrap
  display: flex
  align-items: center

.Post__header__name
  font-weight: 800
  font-size: 16px
  line-height: 22px
  color: var(--foreground-primary)

  &:hover
    text-decoration: underline

.Post__header__date_wrap
  display: flex
  align-items: center

.Post__header__date
  font-size: 14px
  line-height: 18px
  color: var(--foreground-tertiary)

.Post__header__pinned
  color: var(--foreground-primary)
  margin-left: 6px

  &, svg
    width: 16px
    height: 16px

.Post__header__follow
  margin-left: auto

.Post__text
  margin-top: 16px
  font-size: 17px
  line-height: 23px
  padding: 0 16px
  user-select: text

.Post__text__expand
  display: inline-block
  margin-left: 8px
  color: var(--foreground-tertiary)
  cursor: pointer

.Post__footer
  display: flex
  align-items: center
  margin-top: 16px
  justify-content: space-between
  padding: 0 16px

.Post__likes
  font-size: 16px
  line-height: 20px
  padding: 12px 16px
  margin-bottom: -12px
  display: inline-flex
  color: var(--foreground-tertiary)
  text-decoration: underline

.Post__footer__actions
  display: flex

  .Button.secondary
    background: var(--background-secondary) !important

  .Button
    margin-left: 12px

    &:first-child
      margin-left: 0

.Post__views
  display: flex
  align-items: center
  font-size: 15px
  line-height: 18px
  color: var(--foreground-tertiary)

.Post__views__icon
  width: 18px
  height: 18px
  margin-right: 4px

.Post__header__more
  width: 56px
  height: 56px
  display: flex
  align-items: center
  justify-content: center
  margin: -16px -16px 0 auto
  transition: opacity 150ms ease-in-out
  color: var(--foreground-tertiary)

  &:active
    opacity: 0.7

.Post__deleted
  padding: 16px
  display: flex
  align-items: center

.Post__deleted__label
  font-size: 16px
  line-height: 22px
  color: var(--foreground-tertiary)
  flex: 1

.Post__deleted__actions
  margin-left: 16px
  flex: 0 0 auto

.Post__publish_now_button
  display: flex
  justify-content: center
  width: 100%
