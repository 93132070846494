.EditAnime__wrap
  .AttachPickerManager__mini_photos
    padding: 0

.EditAnime__photo_button
  position: relative
  overflow: hidden
  flex: 0 0 auto
  margin-top: 12px
  display: inline-block

  input
    opacity: 0
    position: absolute
    z-index: 2
    top: 0
    left: 0
    width: 100%
    height: 100%
