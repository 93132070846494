.InlineVideo
  position: relative
  width: 100%
  padding-top: 75%

  video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.InlineVideo__info
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: 0
  background: no-repeat center/cover
  display: flex
  align-items: center
  justify-content: center
