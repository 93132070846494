.wrap
  background: var(--tint)
  border-radius: 16px
  padding: 16px
  margin: 0 16px 16px

.cont
  color: #000
  font-size: 20px
  line-height: 28px
  font-weight: bold

.btn
  flex: 0 0 auto
  margin-top: 12px

